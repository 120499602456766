import Vue from 'vue';
import types from '../mutation-types';
import { throwErrorMessage } from 'dashboard/store/utils/api';

import ConversationInboxApi from '../../api/inbox/conversation';

const state = {
  records: {},
  uiFlags: {
    isFetching: false,
    isUpdating: false,
  },
};

export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getByConversationId: _state => conversationId => {
    return _state.records[conversationId];
  },
};

export const actions = {
  show: async ({ commit }, { conversationId }) => {
    commit(types.SET_CONVERSATION_PARTICIPANTS_UI_FLAG, {
      isFetching: true,
    });

    try {
      const response =
        await ConversationInboxApi.fetchParticipants(conversationId);
      commit(types.SET_CONVERSATION_PARTICIPANTS, {
        conversationId,
        data: response.data,
      });
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_CONVERSATION_PARTICIPANTS_UI_FLAG, {
        isFetching: false,
      });
    }
  },

  addParticipant({ commit }, payload) {
    if (!payload || !payload.conversationId || !payload.participant) {
      throw new Error('Payload inválido para addParticipant');
    }
    commit('ADD_PARTICIPANT', payload);
  },

  removeParticipant({ commit }, payload) {
    if (!payload || !payload.conversationId || !payload.participant) {
      throw new Error('Payload inválido para removeParticipant');
    }
    commit('REMOVE_PARTICIPANT', payload);
  },

  update: async ({ commit }, { conversationId, userIds }) => {
    commit(types.SET_CONVERSATION_PARTICIPANTS_UI_FLAG, {
      isUpdating: true,
    });

    try {
      const response = await ConversationInboxApi.updateParticipants({
        conversationId,
        userIds,
      });
      commit(types.SET_CONVERSATION_PARTICIPANTS, {
        conversationId,
        data: response.data,
      });
    } catch (error) {
      throwErrorMessage(error);
    } finally {
      commit(types.SET_CONVERSATION_PARTICIPANTS_UI_FLAG, {
        isUpdating: false,
      });
    }
  },
};

export const mutations = {
  [types.SET_CONVERSATION_PARTICIPANTS_UI_FLAG]($state, data) {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [types.SET_CONVERSATION_PARTICIPANTS]($state, { data, conversationId }) {
    Vue.set($state.records, conversationId, data);
  },

  ADD_PARTICIPANT($state, { conversationId, participant }) {
    const participants = $state.records[conversationId];
    if (participants) {
      participants.push(participant);
    } else {
      Vue.set($state.records, conversationId, [participant]);
    }
  },

  REMOVE_PARTICIPANT($state, { conversationId, participant }) {
    const participants = $state.records[conversationId];
    if (participants) {
      $state.records[conversationId] = participants.filter(
        p => p.id !== participant.id
      );
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
